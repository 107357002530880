import React from 'react'
import { graphql } from 'gatsby'
import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import ContentBlock from '../components/ContentBlock'

import Img from 'gatsby-image'
import styles from '../components/Layout/layout.scss'

const NewsPage = props => {

  const images = props.data.allImagesJson.edges[0].node.childrenImagesJson.reduce((acc, curr) => {
    acc.push(curr.file.childImageSharp.fluid)
    return acc
  }, [])

  return (
    <Layout>
        <ContentBlock content={['news.istanbul.2019']} specialClass='header' />
        <div className="photoRow doubleColumn">
            <div className="photoColumn">
                <Img fluid={images[3]} />
            </div>
            <div className="photoColumn">
                <Img fluid={images[4]} />
            </div>
        </div>
        <div className="textblock">
            <p>
                Special Guest Performance in <a href="https://www.facebook.com/takadhimitr/photos/a.462415057210005/2339183346199824/">PARVAN</a>, annual dance show of <a href="https://takadhimi.com/">Takadhimi Studios</a>.
            </p>
            <p>
                Istanbul - 21st Sep, from 6:30 pm. Sarıyer Boğaziçi Kültür Sanat Merkezi.
            </p>
            <p>
                For tickets, visit: <a href="https://biletinial.com/muzik/parvan-hint-dans-gosterisi">https://biletinial.com/muzik/parvan-hint-dans-gosterisi</a>
            </p>
            <p>
                Kathak Workshop as part of <a href="https://www.facebook.com/takadhimitr/photos/a.462415057210005/2369306859854139/">PARVAN</a>
            </p>
            <p>
                Istanbul - 22nd Sep, 2pm - 6pm. Studio Suma.
            </p>
            <p>
                To attend, contact +90 533 777 2179.
            </p>
        </div>

        <ContentBlock content={[]} />

        <ContentBlock content={['news.ghent.2019']} specialClass='header' />
        <div className="photoRow singleColumn">
            <div className="photoColumn">
                <Img fluid={images[2]} />
            </div>
        </div>
        <div className="textblock">
            <p>
                Women Speaks – Inspired by Mahasati, a Shila Mehta and Maya Sapera Dance Company production.
            </p>
            <p>
                Ghent - 14th June, from 8 pm. Cultuurcentrum Evergem.
            </p>
            <p>
                For  tickets, visit: <a href="https://www.evergem.be/activiteiten/detail/2579/maya-sapera-dance-company-women-speak-inspired-by-mahasati "> https://www.evergem.be/activiteiten/detail/2579/maya-sapera-dance-company-women-speak-inspired-by-mahasati  </a>
            </p>
            <p>
                Brussels - 15th June, from 7:30 pm. Bozar – Centre for Fine Arts.
            </p>
            <p>
                For tickets, visit: <a href="https://www.bozar.be/en/activities/154171-women-speak"> https://www.bozar.be/en/activities/154171-women-speak </a>
            </p>
        </div>

        <ContentBlock content={[]} />

        <ContentBlock content={['news.zuritanzt.2019']} specialClass='header' />
        <div className="photoRow singleColumn">
            <div className="photoColumn">
                <Img fluid={images[1]} />
            </div>
        </div>
        <div className="textblock">
            <p>
                ‘Rhythms &amp; Stories’ workshop at Zürich Tanzt 2019
            </p>
            <p>
                To book the course, visit <a href="https://zuerichtanzt.ch/programm#/artist/kathak-rhythms-stories "> https://zuerichtanzt.ch/programm#/artist/kathak-rhythms-stories   </a>
            </p>
        </div>

        <ContentBlock content={[]} />


        <ContentBlock content={['news.fribourg.2019']} specialClass='header' />
        <div className="photoRow singleColumn">
            <div className="photoColumn">
                <Img fluid={images[0]} />
            </div>
        </div>
        <div className="textblock">
            <p>
                The project tries to draw the sensations commonly experienced in our dreams through movement. The choreography merges kathak and contemporary dance, and seeks to express the sensation of floating experienced in our dreams. In this excerpt, the dream world allows nostalgic exploration of past memories.
            </p>
            <p>
                Choreography and performance: Messaline Gerstein and Anvita Pandey
            </p>
            <p>
                Music : Nick Eyring
            </p>
            <p>
                <a href="https://fetedeladanse.ch/fribourg/programme/2019/5/4/"> https://fetedeladanse.ch/fribourg/programme/2019/5/4/  </a>
            </p>
        </div>

        <ContentBlock content={[]} />
    </Layout>
  )
}


export const NewsImageQuery = graphql`
  query NewsImageQuery {
    allImagesJson: allFile(
      filter: { relativePath: { eq: "grid/news/images.json" } }
    ) {
      edges {
        node {
            childrenImagesJson {
                file {
                    childImageSharp {
                        fluid(maxWidth: 2280) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
      }
    }
  }
`

export default withIntl(NewsPage)
